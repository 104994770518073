<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
            autocomplete="off"
        >
            <b-sidebar id="editHomeSales" aria-labelledby="sidebar-no-header-title" lazy no-header backdrop width="850px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.update}}</span>
                        </div>
                        <div @click="hide" id="hideEditHomeTask" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{ lang.close }}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <v-row style="direction:rtl" class="m-2">
                        <v-col cols="12" md="4">
                            <label>{{ lang.agent }}</label>
                            <b-form-select class="selborder" v-model="support.userid" :options="userslist" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <label>{{ lang.source }}</label>
                            <b-form-select class="selborder" v-model="support.source_id" :options="supportService.customerSource" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <label>{{ lang.product }}</label>
                            <b-form-select class="selborder" v-model="support.product_id" :options="supportService.convertProducts" />
                        </v-col>
                        
                        <v-col cols="12" md="4" style="display:none;">
                            <label>{{ lang.procedure }}</label>
                            <b-form-select class="selborder" v-model="support.procedure_id" :options="supportService.crmActions" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <label>{{ lang.status }}</label>
                            <b-form-select class="selborder" v-model="support.status" :options="supportService.crmStatus" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <label>{{ lang.executed_date }}</label>
                            <b-form-input type="date" class="inborder" @change="getTimes()" v-model="support.executed_date_start" />
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-row>
                                <v-col cols="12" md="4" style="display:none;">
                                    <label>{{ lang.start_date }}</label>
                                    <b-form-input type="date" class="inborder" v-model="support.start_date" />
                                </v-col>
                                <v-col cols="12" md="4" style="display:none;">
                                    <label>{{ lang.executed_date }}</label>
                                    <b-form-input type="date" class="inborder" @change="getTimes()" v-model="support.executed_date_start" />
                                </v-col>
                                <v-col cols="12" md="4" style="display:none;">
                                    <label>{{ lang.dead_line }}</label>
                                    <b-form-input type="date" class="inborder" v-model="support.deadline" />
                                </v-col>
                                <v-col cols="12">
                                    <label>{{ lang.available_times }}</label>
                                    <v-row>
                                        <v-col cols="12" md="2" sm="6" v-for="(item,index) in times" :key="index" @click="checkTime(item)">
                                            <div class="itemSel" style="background:blue" :id="`times_${index}`">
                                                {{ item }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="12">
                            <label>{{ lang.description }}</label>
                            <b-form-textarea type="date" class="inborder" v-model="notes" />
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-row v-for="(itemss,index) in itemNotes" :key="index" style="border:1px solid #000;margin-top:5px;">
                                <v-col cols="12" md="4" class="text-start backBlack">
                                    {{ lang.added_by }} : {{ itemss.added_by }}
                                </v-col>
                                <v-col cols="12" md="4" class="text-start backBlack">
                                    {{ lang.date }} : {{ itemss.added_date }}
                                </v-col>
                                <v-col cols="12" md="4" class="text-start backBlack">
                                    {{ lang.time }} : {{ itemss.added_time }}
                                </v-col>
                                <v-col cols="12" style="border:1px solid #000;">
                                    <p style="white-space: pre-wrap;">{{ itemss.notes }}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                        
                    </v-row>
                </template>
                <template #footer="">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                        <b-button type="button" @click="addJob()" variant="success" class="ma-2" style="width:100px;">{{lang.save}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            id: 0,
            itemNotes: [],
            customersList: [],
            userslist: [],
            statics: [],
            customers: [],
            support: {
                source_id: 1,
                userid: 1,
                doc_type: 'sales',
                customer_id: '',
                service_id: 1,
                department_id: 1,
                product_id: 1,
                procedure_id: 1,
                subject: 'مبيعات - Sales',
                start_date: '',
                executed_date_start: '',
                deadline: '',
                times: [],
                notes: ''
            },
            customer: {
                id: 0,
                customer_type: 2,
                mobile: '',
                full_name: '',
                id_number: '',
                ctype: '',
                company_name: '',
                company_vatid: '',
                address: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city: '',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                industrial: 0,
            },
            times: [],
            notes: '',
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        supportService: function()
        {
            let t = {
                convertProducts: []
            }
            for(let i=0;i<this.statics.length;i++){
                if(!t[this.statics[i].option_phrase]){
                    t[this.statics[i].option_phrase] = [];
                }
                t[this.statics[i].option_phrase].push(
                    {text: this.statics[i][`name_${this.lang.langname}`],value: this.statics[i].value}
                )
            }
            return t
        }
    },
    methods: {
        getSales(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear')); 
            post.append("type","getCustomerSales");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[id]",this.id)
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then((response) => {
                if(response && response.data){
                    this.support = response.data.results.data[0];
                    this.itemNotes = response.data.results.itemNotes;
                }
            })
        },
        checkTime(item){
            let index = -1;
            console.log([item,typeof this.times,this.times])
            let it = [];
            if(typeof this.times == 'object'){
                Object.keys(this.times).forEach(key => {
                    it.push(this.times[key]);
                })
            }else{
                it = this.times;
            }
            this.times = it;
            console.log(this.times.length, it.length)
            for(let i=0;i<this.times.length;i++){
            
                // console.log([this.times[i],item,this.times[i] === item]);
                if(this.times[i] === item){
                    index = i;
                }
            }
            // document.getElementById('times_'+index).setAttribute("style",'background:green')
            // this.support.times.push(this.times[index]);
            // if(index != -1){
                
                if(this.support.times.includes(this.times[index])){
                    const ind = this.support.times.indexOf(item);
                    this.support.times.splice(ind,1);
                    document.getElementById('times_'+index).setAttribute("style",'background:blue')
                }
                else{
                    document.getElementById('times_'+index).setAttribute("style",'background:green')
                    this.support.times.push(this.times[index]);
                    
                }
            // }
            
        },
        addJob(){
            let message = '';
            let co = true;
            if(this.support.start_date == ""){
                message = this.lang.start_date_require
                co = false;
            }
            else if(this.support.executed_date_start == ""){
                message = this.lang.executed_date_require
                co = false;
            }
            else if(this.support.deadline == ""){
                message = this.lang.deadline_require
                co = false;
            }
            else if(this.notes == ""){
                message = this.lang.notes_require
                co = false;
            }
            else if(this.support.times.length == 0){
                message = this.lang.times_require
                co = false;
            }
            if(!co) {
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","editTeamSalesCalendar")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            Object.keys(this.support).forEach(key => {
                if(key == 'times'){
                    post.append(`data[sales][${key}]`,JSON.stringify(this.support[key]))
                }else{
                    post.append(`data[sales][${key}]`,this.support[key])
                }
            })
            post.append('data[id]',this.id);
            post.append('data[notes]',this.notes)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => { 
                this.$snotify.error('تمت الاضافة بنجاح', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getSales();
                document.getElementById('hideEditHomeTask').click();
            });
        },
        getTimes(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getTimes")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[userid]",this.support.userid);
            post.append("data[executed_date_start]",this.support.executed_date_start);
            this.support.times = [];
            // this.times = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => { 

                this.times = response.data.results.data;
            });
        },
        getCustomers() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getcustomres")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => { 
                this.customersList = response.data.results.data;
            });
        },
        async getUsers(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getUsers");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[status]',1);
            post.append('data[onlyMe]',1);
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            if(response && response.data && response.data.results && response.data.results.data && response.data.results.data.results){
                const res = response.data.results.data.results;
                // console.log("asdfas",res);
                for(let i = 0;i < res.length;i++){
                    this.userslist.push({text: res[i].full_name, value: res[i].id})
                    this.support.userid = res[0].id;
                }
            }
        },
        reset(){
            this.support = {
                source_id: 1,
                userid: 1,
                doc_type: 'support',
                customer_id: '',
                service_id: 1,
                department_id: 1,
                product_id: 1,
                procedure_id: 1,
                subject: 'مبيعات - Sales',
                start_date: '',
                executed_date_start: '',
                deadline: '',
                times: [],
                notes: ''
            }
            this.customer =  {
                id: 0,
                customer_type: 2,
                mobile: '',
                full_name: '',
                id_number: '',
                ctype: '',
                company_name: '',
                company_vatid: '',
                address: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city: '',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                industrial: 0,
            }
            // this.times = [];
        },
        getStatics(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , 'getStaticOptions');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]','');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    this.statics = response.data.results.data;
                }
            )
        },
        getCustomerInfo(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , 'getCustomers');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            const mobile = this.customer.mobile;
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {

                    // console.log(response.data.results.data);
                    if(response.data.results.data[0]){
                        this.customer = response.data.results.data[0];
                        this.customer.mobile = mobile;
                        this.support.customer_id = response.data.results.data[0].id ? response.data.results.data[0].id : 0;
                    }else{
                        this.customer =  {
                            id: 0,
                            customer_type: 2,
                            mobile: mobile,
                            full_name: '',
                            id_number: '',
                            ctype: '',
                            company_name: '',
                            company_vatid: '',
                            address: '',
                            building_no: '',
                            street_name: '',
                            branch_number: '',
                            district: '',
                            zipcode: '',
                            city: '',
                            crt_number: '',
                            entity_name: '',
                            branch_name: '',
                            industrial: 0,
                        }
                    }
                    
                }
            )
        },
    },
    created() {
        this.getCustomers()
        this.getUsers()
        this.getStatics()
    },
}
</script>
<style scoped>
.itemSel{
    margin:2px;
    color:#fff;
    text-align: center;
    padding:5px;
    cursor: pointer;
}
</style>